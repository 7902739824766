<template>
  <b-card style="padding: 0;">
    <b-row style="height: 80%;">
      <b-col md="4" style="margin: 1.5rem;">
        <b-row>
          <b-col md="6" style="padding-top: 1rem;">
            <b-button
              :variant="buttons_config[0].color"
              block
              @click="changeMode(0)"
            >
              <feather-icon icon="CreditCardIcon" class="mr-50" />
              <span class="align-middle">Tabela</span>
            </b-button>
          </b-col>
          <b-col md="6" style="padding-top: 1rem;">
            <b-button
              :variant="buttons_config[1].color"
              block
              @click="changeMode(1)"
            >
              <feather-icon icon="MapPinIcon" class="mr-50" />
              <span class="align-middle">Mapa de pontos</span>
            </b-button>
          </b-col>
          <b-col md="6" style="margin-top: 1rem">
            <b-button
              :variant="buttons_config[2].color"
              block
              @click="changeMode(2)"
            >
              <feather-icon icon="ThermometerIcon" class="mr-50" />
              <span class="align-middle">Mapa de calor</span>
            </b-button>
          </b-col>
          <b-col md="6" style="margin-top: 1rem">
            <b-button
              v-b-tooltip.hover.v-success title="Relatório de permanência em área"
              :variant="buttons_config[3].color"
              block
              class="btn-icon"
              @click="changeMode(3)"
            >
              <feather-icon icon="TriangleIcon" class="mr-50" />
              <span class="align-middle">RPA</span>
            </b-button>
          </b-col>
        </b-row>
        <b-col md="12" style="margin-top: 1.5rem">
          <b-form-group label="Dispositivo" label-for="group">
            <v-select
              multiple
              id="group"
              v-model="selected_device"
              label="name"
              :options="get_device.filter((data) => data.groupId == $store.state.active_group.id)"
              :selectable="
                () =>
                  selected_device.length <
                  buttons_config[last_button_pressed].devices_limit
              "
            />
          </b-form-group>
          <label for="first_date">Data de início</label>

          <flat-pickr
            style="margin-bottom: 1rem"
            id="first_date"
            v-model="first_date"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
          />
          <label for="last_date">Data final</label>
          <flat-pickr
            style="margin-bottom: 1rem"
            id="last_date"
            v-model="last_date"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
          />
          <b-form-group
            v-if="last_button_pressed == 0 || last_button_pressed == null"
            style="margin-top: 1.4rem; margin-bottom: 1.4rem"
            label="Variável"
            label-for="variable"
          >
            <v-select
              id="variable"
              multiple
              v-model="selected_variable"
              label="name"
              :options="get_variables"
            />
          </b-form-group>
          <b-col md="12" style="margin-top: 1rem">
            <b-button variant="outline-info" block @click="findData()">
              <span class="align-middle">Procurar</span>
            </b-button>
          </b-col>
        </b-col>
      </b-col>

      <!-- Tabela para relatório do tipo "Tabela" -->
      <b-col md="7" v-if="mode == 3">
        <b-overlay
          style="width: 100%; height: 100%"
          opacity="0.5"
          :show="overlay"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-card no-body class="card-developer-meetup" v-if="ready">
            <b-col md="6" style="margin-bottom: 1rem">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="exportToJsonFile(new_data)"
              >
                <feather-icon icon="PlayIcon" class="mr-50" />
                <span class="align-middle">JSON</span>
              </b-button>              
            </b-col>

            <!-- Tabela para o relatório de "Tabela" -->
            <b-table
              :per-page="perpage"
              :current-page="current_page"
              :fields="fields"
              responsive
              :items="new_data"
            >
              <template #cell(valueTime)="data">
                {{ new Date(data.item.valueTime * 1000).toLocaleString() }}
              </template>

              <template #cell(createdAt)="data">
                {{ new Date(data.item.createdAt).toLocaleString() }}
              </template>

              <template #cell(variableId)="data">
                {{
                  get_variables.find(
                    (find_data) => find_data.id == data.item.variableId
                  ).name
                }}
              </template>

              <template #cell(deviceId)="data">
                {{
                  get_device.find(
                    (find_data) => find_data.id == data.item.deviceId
                  ).name
                }}
              </template>
            </b-table>
            <b-pagination
              style="margin-right: 2rem"
              v-model="current_page"
              :total-rows="new_data.length"
              :per-page="perpage"
              align="right"
              class="my-0"
            />
          </b-card>
        </b-overlay>
      </b-col>

      <b-col md="7" v-if="mode == 4 && !no_have_datas" style="margin-top:3rem">
      <b-overlay
          style="width: 100%; height: 100%"
          opacity="0.5"
          :show="overlay"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
        <b-button
          style="margin-bottom: 1rem"
          variant="outline-success"
          @click="generateReport()"
        >
          <span class="align-middle">PDF</span>
        </b-button>

        <!-- Tabela para o relatório de "Entrada na cerca" -->
        <b-table
          :per-page="perpage"
          :current-page="fence_current_page"
          :fields="fence_fields"
          responsive
          :items="fence_times"
        >
          <template #cell(fence)="data">
            {{ conditions_filtered_by_type[data.item[3]].rules.split('title:')[1].split(',')[0]}}
          </template>
          <template #cell(variable)="data">           
            <p v-if="data.item[1] != null" style="margin-bottom: 0;">{{get_variables.find(e => e.id == data.item[1]).name}}</p>
          </template>
          <template #cell(value)="data">           
            <p v-if="data.item[2] != null && data.item[1] != null" style="margin-bottom: 0;">{{data.item[2]}} {{get_variables.find(e => e.id == data.item[1]).unit}}</p>
          </template>
          <template #cell(time)="data">           
            {{data.item[0]}}
          </template>
        </b-table>
        </b-overlay>
      </b-col>
      <b-col md="7" style="height: 100%; margin-top: 2rem;">
        <b-overlay
          style="height: 100%;"
          v-if="ready && mode != 3"
          opacity="0.5"
          :show="overlay"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-col md="12" v-if="mode == 1">
            <label for="demo-sb">Pontos</label>
            <vue-slider
              v-model="step"
              :direction="direction"
              :max="slider_limit"
            />
          </b-col>
          <!--  mode = Caso for 1, será mapa de pontos; Se for 2, será mapa de calor -->
          <b-col md="12" :key="'map' + cont" style="height: 70vh">
            <Mapbox
              :mode="mode"
              :request="mapbox_devices"
              :start_date="start_date"
              :final_date="final_date"
            />
          </b-col>
        </b-overlay>
      </b-col>
    </b-row>
    <VueHtml2pdf
        v-if="show_pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="pdf_filename" 
        :pdf-quality="2 "
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
          <div style="padding: 4rem">          
            <b-row>
              <h3 style="flex: auto; text-align: center">Relatório de permanência em área: CBMM</h3>
              <b-col md="12" style="margin-bottom: 0.5rem; margin-top: 1rem">
                <span style="font-weight: bolder; margin-right: 0.4rem">
                  Identificador:
                </span>
                {{ selected_device[0].name}}
              </b-col>
              <b-col md="12">
                <span style="font-weight: bolder; margin-right: 0.4rem">
                  Período:
                </span>
                {{first_date}} até {{last_date}}
              </b-col>
            </b-row>
            <b-table
            style="margin-top: 2rem"
              :per-page="perpage"
              :current-page="fence_current_page"
              :fields="fence_fields"
              responsive
              :items="fence_times"
            >
            <!-- // conditions_filtered_by_type[index].message -->
              <template #cell(fence)="data">
                {{ conditions_filtered_by_type[data.item[3]].rules.split('title:')[1].split(',')[0]}}
              </template>
              <template #cell(variable)="data">           
                <p v-if="data.item[1] != null" style="margin-bottom: 0;">{{get_variables.find(e => e.id == data.item[1]).name}}</p>
              </template>
              <template #cell(value)="data">           
                <p v-if="data.item[2] != null && data.item[1] != null" style="margin-bottom: 0;">{{data.item[2]}} {{get_variables.find(e => e.id == data.item[1]).unit}}</p>
              </template>
              <template #cell(time)="data">           
                {{data.item[0]}}
              </template>
            </b-table>
          </div>
        </section>
    </VueHtml2pdf>
  </b-card>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
const { appLogoImage } = $themeConfig.app
import VueHtml2pdf from 'vue-html2pdf'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  BOverlay,
  BCard,
  BPagination,
  BCardText,
  VBTooltip,
  BFormDatepicker,
  BRow,
  BCol,
  BFormGroup,
  BCardTitle,
  BCardBody,
  BMediaAside,
  BMedia,
  BMediaBody,
  BAvatar,
  BAvatarGroup,
  BButton,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Cookies from "js-cookie";
import store from "@/store/index";
import flatPickr from "vue-flatpickr-component";
import EventBus from "@/store/eventBus";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Mapbox from "../layouts/components/ReportsMapbox.vue";
import api from "../service/api.js";

export default {
  components: {
    BLink,
    BImg,    
    VueHtml2pdf,
    VBTooltip,
    Mapbox,
    BOverlay,
    flatPickr,
    VueSlider,
    ToastificationContent,
    BTable,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BFormDatepicker,
    BMedia,
    BMediaAside,
    BRow,
    BCol,
    vSelect,
    BFormGroup,
    BMediaBody,
    BAvatar,
    BAvatarGroup,
    BButton,
    BBadge,
    BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  async created() {
    var self = this;

    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }

    if (Cookies.get("token_api") == undefined) {
      top.location.href = "/login";
    }
    if (store.state.refresh_interval == "") {
      store.state.refresh_interval = setInterval(api.refresh_token, 10000);
      store.state.refresh_interval = true;
    }

    EventBus.$on("slider_limit", (data) => {
      self.change_limit(data);
    });

    EventBus.$on("close_overlay", () => {
      self.overlay = false;
    });
    
    self.get_device = await api.get_devices();    
    self.get_variables = await api.get_variables();    
  },
  data() {
    return {
      pdf_filename: '',
      show_pdf: false,
      appLogoImage,
      conditions_filtered_by_type: [],
      fence_current_page: 1,
      fence_times: [],
      area_events: [],
      last_button_pressed: 0,
      buttons_config: [
        {
          id: 0,
          color: "success",
          devices_limit: 20,
        },
        {
          id: 1,
          color: "outline-success",
          devices_limit: 1,
        },
        {
          id: 2,
          color: "outline-success",
          devices_limit: 1,
        },
        {
          id: 3,
          color: "outline-success",
          devices_limit: 1,
        },
      ],
      overlay: true,
      mode: 0,
      slider_limit: 0,
      dir: "ltr",
      step: 0,
      cont: 0,
      start_date: "",
      final_date: "",
      mapbox_devices: "",
      ready: false,
      new_data: [],
      current_page: 1,
      perpage: 7,
      data_created: [],
      fields: [
        {
          key: "value",
          label: "Valor",
          sortable: true,
        },
        {
          key: "variableId",
          label: "Variável",
          sortable: true,
        },
        {
          key: "deviceId",
          label: "Dispositivo",
          sortable: true,
        },
        {
          key: "valueTime",
          label: "Data dispositivo",
        },
        {
          key: "createdAt",
          label: "Data servidor",
        },
      ],

      fence_fields: [
        {
          key: "fence",
          label: "Cerca",
          sortable: false,
        },
        {
          key: "variable",
          label: "Variável",
          sortable: false,
        },
        {
          key: "value",
          label: "Valor",
          sortable: false,
        },
        {
          key: "time",
          label: "Tempo na área (Minutos)",
          sortable: false,
        },
      ],
      get_data: [],
      selected_device: "",
      selected_variable: "",
      first_date: "",
      last_date: "",

      get_device: [],
      get_variables: [],
    };
  },
  methods: {
    // Gerar PDF
    generateReport () {
      this.pdf_filename = 'Relatório de permanência em área - ' + this.selected_device[0].name + 'agosto/2022'      
      this.$refs.html2Pdf.generatePdf()
    },
    // Verificar qual modo utilizar quando apertado o botão Procurar
    findData() {
      var self = this;
      if (self.last_button_pressed == -1 || self.last_button_pressed == null) {
        self.showToast(
          "danger",
          "Erro",
          "BellIcon",
          "Selecione algum tipo de relatório"
        );
      }
      if ((self.first_date != "" && self.last_date != "")) {
        if (self.last_button_pressed == 0) {
          self.findTableData();
        }
        if (self.last_button_pressed == 1 || self.last_button_pressed == 2) {
          var self = this;
          self.overlay = true;
          self.ready = false;
          self.cont += 1;

          if (self.selected_device == "") {
            self.showToast(
              "danger",
              "Erro",
              "BellIcon",
              "É necessário especificar um dispositivo"
            );
            self.overlay = false;
            return
          } else {
            self.mapbox_devices = self.selected_device[0].id;
            if (self.first_date != "") {
              self.start_date = new Date(self.first_date);
            }
            if (self.last_date != "") {
              self.final_date = new Date(self.last_date);
            }
            self.mode = self.last_button_pressed;
            self.ready = true; // Renderiza o mapa de acordo com o modo selecionado
          }
        }
        if (self.last_button_pressed == 3) {
          self.findDataFence();
        }
      } else {
        self.showToast(
          "danger",
          "Erro",
          "BellIcon",
          "É necessário especificar o intervalo de tempo para gerar o relatório"
        );
      }
    },
    // Alterar estado dos botões
    changeMode(button_id) {
      if (this.last_button_pressed == null) {
        this.buttons_config[button_id].color = "success";
      } else {
        this.buttons_config[this.last_button_pressed].color = "outline-success";
        this.buttons_config[button_id].color = "success";
      }
      this.last_button_pressed = button_id;
      this.selected_device = [];
    },
    // Atualizar valor máximo do slider. Função acionada pelo ReportsMapbox.vue
    change_limit(data) {
      this.slider_limit = data;
    },
    // Notificação
    showToast(variant, title, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    // Função para determinar as datas do relatório tipo Tabela
    async findTableData() {
      var self = this;
      self.ready = false;
      var request_devices = "";
      var request_variables = "";
      self.final_date = 0;
      self.start_date = 0;
      self.new_data = [];
      self.mode = 3;
      self.overlay = true;

      if (self.selected_variable == "" || self.selected_device == "") {
        self.showToast(
          "danger",
          "Erro",
          "BellIcon",
          "É necessário especificar um dispositivo e uma variável"
        );
        self.overlay = false;
        return 
      } else {
        if (self.first_date != "") {
          self.start_date = new Date(self.first_date).toISOString();
        }
        if (self.last_date != "") {
          self.final_date = new Date(self.last_date).toISOString();
        }        

        self.selected_device.forEach((element) => {
          request_devices += "&deviceId=" + element.id;
        });

        self.selected_variable.forEach((element) => {
          if (element.id != -1) {
            request_variables += "&variableId=" + element.id;
          } else {
            request_variables += "&variableId=1&variableId=2";
          }
        });

        let request_interval =
          "&start_time=" + self.start_date + "&end_time=" + self.final_date;

        let response = await api.find_data(
          request_devices,
          request_variables,
          request_interval
        );
        self.overlay = false;
        if(response.error == "DATAS NÃO ENCONTRADAS" || response.error == 'Não há datas cadastradas no periodo desejado!'){
          self.showToast(
            "danger",
            "Dados não encontrados",
            "BellIcon",
            "Não existe dados no período especificado para este dispositivo"
          );
          return
        } else {
          self.new_data = response.datas;        
          self.ready = true;
        }
        
      }
    },
    // Função para determinar as datas do RPA
    async findDataFence() {
      var self = this;
      self.overlay = true;
      self.ready = false;
      self.cont += 1;
      self.mode = 4;
      self.no_have_datas = false;
      
      self.start_date = (new Date(self.first_date).getTime())/1000;
      self.final_date = (new Date(self.last_date).getTime())/1000;

      let all_conditions = await api.get_conditions();
      self.conditions_filtered_by_type = all_conditions.filter(
        (e) =>
          e.rules.split("sinal: ")[1].split(",")[0] == "6" ||
          e.rules.split("sinal: ")[1].split(",")[0] == "7"
      );
      let conditions_id = [];
      self.conditions_filtered_by_type.forEach((item, index) => {
        conditions_id[index] = item.id
      });

      let all_events = await api.get_events();

      if(all_events == ''){
        self.showToast(
          "danger",
          "Nenhuma data foi encontrada",
          "BellIcon",
          "Não há nenhum evento no período escolhido"
        );
        self.mode = 0;
        self.overlay = false;
        return
      }
      
      let events_filtered_by_condition = all_events.filter( e => (conditions_id.includes(e.condicoId)) == true)

      if(events_filtered_by_condition.length == 0){
        self.no_have_datas = true;        
      }

      let events_filtered_by_date = events_filtered_by_condition.filter(e => e.valueTime >= self.start_date && e.valueTime <= self.final_date)

      let events_filtered_by_devices = events_filtered_by_date.filter(
        (e) => e.deviceId == self.selected_device[0].id
      );

      let events_remove_duplicates = [
        ...new Map(
          events_filtered_by_devices.map((item) => [item.valueTime, item])
        ).values(),
      ];

      let events_filtered_by_conditionsNull = events_remove_duplicates.filter(
        (e) => e.condicoId != null
      );

      let ordered_events_data = events_filtered_by_conditionsNull.sort(
        (a, b) => a.valueTime - b.valueTime
      );

      let events = [];
      self.conditions_filtered_by_type.forEach((item, index) => { // Separando os eventos pelas condições, cada posição do evento são os eventos de uma condição
        events[index] = ordered_events_data.filter(
          (e) => e.condicoId == item.id
        );
      });

      self.area_events = [];
      let last_index = 0;
      // Separando os eventos em grupos. Momento - Grupo de evento que são considerados um apenas
      for (let i = 0; i < events.length; i++) { // Realizar filtro por condições, ou seja, a cada elemento de events
        last_index = 0;
        self.area_events[i] = [];
        events[i].forEach((data, index) => {
          if (index == events[i].length - 1) { // Caso for o ultimo dado
            self.area_events[i].push({
              start_date: new Date(events[i][last_index + 1].valueTime*1000),
              final_date: new Date(data.valueTime*1000),
              time_in_area:
                data.valueTime - events[i][last_index + 1].valueTime, // Data término do momento - data de início do momento
              variableId: data.variableId,
              valueVariable: data.valueVariable
            });
          } else { // Se não for o ultimo dado
            if (events[i][index + 1].valueTime - data.valueTime > 500 || events[i][last_index+1].valueVariable != data.valueVariable || events[i][last_index+1].variableId != data.variableId) { // Delay entre cada evento, caso maior que 500s, é considerado como outro momento
              if (self.area_events[i].length == 0) { // Se for o primeiro momento a ser registrado
                self.area_events[i].push({
                  start_date: new Date(events[i][0].valueTime*1000),
                  final_date: new Date(events[i][index].valueTime*1000),
                  time_in_area:
                    events[i][index].valueTime -
                    events[i][0].valueTime,
                  variableId: data.variableId,
                  valueVariable: data.valueVariable
                });
                
              } else {
                self.area_events[i].push({
                  start_date: new Date(data.valueTime*1000),
                  final_date: new Date(events[i][last_index + 1].valueTime*1000),
                  time_in_area:
                    data.valueTime -
                    events[i][last_index + 1].valueTime,
                    variableId: events[i][index - 1].variableId,
                    valueVariable: events[i][index - 1].valueVariable
                });
              }
              last_index = index;
            }
          }
        });
      }
      let unique_valueVariable = [];
      self.area_events.forEach((element, index) => {
        unique_valueVariable[index] = [];
        element.forEach(item => {
          try{
            let response = unique_valueVariable[index].find(e => e.valueVariable == item.valueVariable);
            if(response == undefined){
              unique_valueVariable[index].push({
              valueVariable: item.valueVariable
            })
            }
          } catch {
            unique_valueVariable[index].push({
              valueVariable: item.valueVariable
            })
          }
        });
      });
      let filtered_area_events = [];

      self.area_events.forEach((item, index) => {
        filtered_area_events[index] = []
        for(let i = 0; i < unique_valueVariable[index].length; i++){
          filtered_area_events[index][i] = [];
          let response = item.filter(e => e.valueVariable == unique_valueVariable[index][i].valueVariable)
          filtered_area_events[index][i] = response;
        }
      });
      self.fence_times = [];
      let cont = 0;
      filtered_area_events.forEach((item, index) => {
        for(let i = 0; i < item.length; i++){ 
          let operator = 0;
          let variableId = 0;
          let valueVariable = 0;
          for(let j = 0; j < item[i].length; j++){
            operator += item[i][j].time_in_area;    
            variableId = item[i][j].variableId;        
            valueVariable = item[i][j].valueVariable;
          }
          self.fence_times[cont] = [self.secondsToTime(operator), variableId, valueVariable, index];
          cont += 1;
        }
      });
      self.overlay = false;
      this.show_pdf = true;
    },
    exportToJsonFile(jsonData) {
      let dataStr = JSON.stringify(jsonData);
      let dataUri =
        "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

      let exportFileDefaultName = "data.json";

      let linkElement = document.createElement("a");
      linkElement.setAttribute("href", dataUri);
      linkElement.setAttribute("download", exportFileDefaultName);
      linkElement.click();
    },
    // secondsToTime: Usado no RPA para exibir a data, entra com os segudos e te retorna em formato de hora.
    secondsToTime(secs)
    {
      secs = Math.round(secs);
      var hours = Math.floor(secs / (60 * 60));

      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60);

      var obj = hours + ' horas e ' + minutes + ' minutos'
      if(hours == 1){
        obj.replace('horas', 'hora')
      }
      if(hours == 0){
        obj = minutes + ' minutos';
      }
      if(minutes == 1){
        obj.replace('minutos', 'minuto');
      }
      if(minutes == 0){
        obj = hours + ' horas'
      }
      return obj;
    }
  },
  watch: {
    // Quando o valor de step é alterado, é emitido um sinal para atualizar o valor em ReportsMapbox.vue
    step: function() {
      EventBus.$emit("next_line", this.step);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
