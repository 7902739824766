<template>
  <b-row style="height: 100%">
    <b-col md="9" style="height: 100%; width: 100%; margin-bottom: 2rem; display: contents;">
      <b-card class="mb-8" style="height: 100%; width: 100%">
        <div id="map-container" style="width: 100%; height: 100%">
          <MglMap
            container="map-container"
            :accessToken="accessToken"
            :mapStyle="mapStyle"
            :center="center"
            :zoom="zoom"
            @load="onMapLoaded"
          >
            <!-- MglMarker para relatórios mapa -->
            <div>              
              <MglMarker
                v-if="ready && mode == 1" 
                color="#00D455"
                :key="'MakerId_' + cont"
                :coordinates="markers_data[0]"
              >
                <MglPopup>
                  <div class="demo-inline-spacing">
                    <b-avatar
                      variant="light-dark"
                      style="margin-right: 0.5rem; margin-top: 0rem"
                    >
                      <feather-icon
                        :icon="
                          possible_icons[
                            devices.find((find_data) => find_data.id == request)
                              .icon
                          ].icon
                        "
                        size="20rem"
                      />
                    </b-avatar>
                    <div style="margin-top: 0rem">
                      <h5 class="mb-0">
                        {{
                          devices.find((find_data) => find_data.id == request)
                            .name
                        }}
                      </h5>
                      <small class="text-muted" v-if="markers_data[0] != undefined">
                        {{ new Date(markers_data[0][2] * 1000).toLocaleString() }}
                      </small>
                    </div>
                  </div>
                </MglPopup>
              </MglMarker>
            </div>
            <MglNavigationControl position="top-right" />
          </MglMap>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BCardText,
  BFormRadio,
  BButton,
  BFormSpinbutton,
} from "bootstrap-vue";
import "mapbox-gl/dist/mapbox-gl.css";
import "v-mapbox/dist/v-mapbox.css";
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglNavigationControl, MglPopup } from "v-mapbox";
import store from "@/store/index";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import EventBus from "@/store/eventBus";
import api from '../../service/api.js'

export default {
  props: ["request", "start_date", "final_date", "mode"],
  components: {
    BFormSpinbutton,
    BFormCheckbox,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BFormRadio,
    MglMap,
    MglMarker,
    BButton,
    MglNavigationControl,
    MglPopup,
    MapboxDraw,
  },

  data() {
    return {
      markers_data: [],
      pan_data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: "",
            },
          },
        ],
      },
      heat_data: {
        type: "FeatureCollection",
        crs: {
          type: "name",
        },
        features: [],
      },
      step: 0,
      wall: [],
      points: [],
      destroy_it: false,
      refresh_data: [],
      map_coords: [],
      new_data: [],
      cont: 0,
      coordinates: [],
      draw: "",
      ready: false,
      possible_icons: [
        {
          icon: "BoxIcon",
          text: "Cubo",
          id: 0,
          selected: "flat-success",
        },
        {
          icon: "BatteryIcon",
          text: "Bateria",
          id: 1,
          selected: "flat-darken-2",
        },
        {
          icon: "DropletIcon",
          text: "Humidade",
          id: 2,
          selected: "flat-darken-2",
        },
        {
          icon: "ThermometerIcon",
          text: "Temperatura",
          id: 3,
          selected: "flat-darken-2",
        },
        {
          icon: "NavigationIcon",
          text: "Seta",
          id: 4,
          selected: "flat-darken-2",
        },
        {
          icon: "Navigation2Icon",
          text: "Seta#2",
          id: 5,
          selected: "flat-darken-2",
        },
        {
          icon: "MousePointerIcon",
          text: "Mouse",
          id: 6,
          selected: "flat-darken-2",
        },
        {
          icon: "TruckIcon",
          text: "Caminhão",
          id: 7,
          selected: "flat-darken-2",
        },
        {
          icon: "UserIcon",
          text: "Usuário",
          id: 8,
          selected: "flat-darken-2",
        },
        {
          icon: "WatchIcon",
          text: "Relógio",
          id: 9,
          selected: "flat-darken-2",
        },
        {
          icon: "EyeIcon",
          text: "Olho",
          id: 10,
          selected: "flat-darken-2",
        },
        {
          icon: "MusicIcon",
          text: "Música",
          id: 11,
          selected: "flat-darken-2",
        },
        {
          icon: "HeartIcon",
          text: "Coração",
          id: 12,
          selected: "flat-darken-2",
        },
        {
          icon: "HomeIcon",
          text: "Casa",
          id: 13,
          selected: "flat-darken-2",
        },
      ],
      all_latitude: [],
      all_longitude: [],
      accessToken:
        "pk.eyJ1IjoiZHRpYWdvIiwiYSI6ImNrYzB4b2JlODE4azMyc3U2dnJ0M3RzdmIifQ.JjLKI9mfpEot4J2FNEtNOQ",
      mapStyle: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [-46.9397181, -19.5933794],
      coordinatesMarker: [],
      zoom: 4,
      devices: [],
    };
  },
  async created() {
    var self = this;
    this.mapbox = Mapbox;    

    EventBus.$on("next_line", (data) => {
      self.next_step(data);
    });
  },
  destroyed() {
    this.destroy_it = true;
  },
  methods: {
    async onMapLoaded(event) {
      this.map = event.map;
      this.$store.map = event.map;
      this.map.resize();
      this.asyncActions = event.component.actions;

      let self = this;

      this.devices = await api.get_devices();   
      // Mode = 1 -> Mapa de pontos/ Mode = 2 -> Mapa de calor
      if(this.mode == 2){
        this.plotHeatMap(this.request, self.start_date, self.final_date);
      } else {
        this.plotPointMap(self.request, self.start_date, self.final_date);
      }
      
      this.ready = true;
      EventBus.$emit("close_overlay");
    },

    async plotHeatMap(device, start_date, final_date) {
      let request_variables = "&variableId=7";
      let request_device = "&deviceId=" + device;
      start_date = new Date(start_date).toISOString();
      final_date = new Date(final_date).toISOString();
      let request_invertal =
        "&start_time=" + start_date + "&end_time=" + final_date;
      let response = await api.find_data(
        request_device,
        request_variables,
        request_invertal
      );
      
      try{
        if(response.error == "DATAS NÃO ENCONTRADAS" || response.error == 'Não há datas cadastradas no periodo desejado!'){
          EventBus.$emit('alert', 'danger', 'Dados não encontrados', 'Não existe dados no período especificado para este dispositivo')
          return
        }
      } catch {}

      let filtered_response = response.datas.filter(
        (e) => e.valueTime > 1
      ); // Retirando todas as mensagens com valueTime igual zero, ou seja mensagens erradas
      filtered_response.forEach((item, index) => {
        try {
          this.heat_data.features.push({
            type: "Feature",
            properties: {
              // id: "ak16994521",
              mag: 1,
              time: 1507425650893,
              felt: null,
              tsunami: 0,
            },
            geometry: {
              type: "Point",
              coordinates: [item.value.split(',')[1], item.value.split(',')[0], 0],
            },
          });
        } catch {}
      });

      this.map.addSource("earthquakes", {
        type: "geojson",
        data: this.heat_data,
      });

      this.map.addLayer(
        {
          id: "earthquakes-heat",
          type: "heatmap",
          source: "earthquakes",
          maxzoom: 24,
          paint: {
            "heatmap-radius": 10,
            "heatmap-opacity": 0.7,
            "heatmap-intensity": 1,
          },
        },
        "waterway-label"
      );
      EventBus.$emit("stop_heat_loading");
    },

    async plotPointMap(device, start_date, final_date){
      let request_variables = "&variableId=7";
      let request_device = "&deviceId=" + device;
      start_date = new Date(start_date).toISOString();
      final_date = new Date(final_date).toISOString();
      let request_invertal =
        "&start_time=" + start_date + "&end_time=" + final_date;
      let response = await api.find_data(
        request_device,
        request_variables,
        request_invertal
      );
      
      try{
        if(response.error == "DATAS NÃO ENCONTRADAS" || response.error == 'Não há datas cadastradas no periodo desejado!'){
          EventBus.$emit('alert', 'danger', 'Dados não encontrados', 'Não existe dados no período especificado para este dispositivo')
          return
        }
      } catch {}

      let filtered_response = response.datas.filter(
        (e) => e.valueTime > 1
      ); // Retirando todas as mensagens com valueTime igual zero, ou seja mensagens erradas
      this.new_data = []
      filtered_response.forEach((item, index) => {
        this.new_data[index] = [
          item.value.split(',')[1],
          item.value.split(',')[0],
          item.valueTime
        ]
      });
      console.log("New_data e: ", this.new_data)
      this.pan_data.features[0].geometry.coordinates = [this.new_data[0]];
      this.map.addSource("trace", { type: "geojson", data: this.pan_data });

      this.map.addLayer({
        id: "trace",
        type: "line",
        source: "trace",
        paint: {
          "line-color": "yellow",
          "line-opacity": 0.75,
          "line-width": 5,
        },
      });
      EventBus.$emit("slider_limit", this.new_data.length);

      this.map.jumpTo({ center: this.new_data[0], zoom: 17 });
      this.map.setPitch(10);
    },

    next_step(step) {
      this.pan_data.features[0].geometry.coordinates = this.new_data.slice(
        0,
        step
      );
      this.map.getSource("trace").setData(this.pan_data);

      this.markers_data[0] = this.new_data[step - 1];
      this.map.panTo(this.new_data[step - 1]);
      this.cont += 1;
    },
  },
};
</script>

<style>
</style>
